

























import { Vue, Component, PropSync, Ref } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'

@Component
export default class ReportDialog extends Vue {
  @PropSync('visible', { default: false }) syncedVisible: boolean

  @Ref() form: ElForm

  data = { activePeriod: 30 }
  rules = {}

  get areas() {
    return this.$store.state.areas.areas
  }

  get rootArea() {
    return this.areas[0]
  }

  async mounted() {
    if (this.areas.length === 0) {
      await this.$store.dispatch('areas/fetchAreas')
    }

    if (this.rootArea.activePeriod) {
      this.data.activePeriod = this.rootArea.activePeriod
    }
  }

  async submit() {
    const { data } = await this.$api.europa.updateArea(this.rootArea.id, this.data)

    if (data.code === 0) {
      this.$message({ message: '配置成功', type: 'success' })
      this.close()

      this.$store.dispatch('areas/fetchAreas')
    }
  }

  close() {
    this.syncedVisible = false
  }
}
