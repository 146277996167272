async function asyncPool(array: any[], iteratorFn: any, poolLimit?: number, callback?: any) {
  if (!poolLimit) poolLimit = 5
  const ret = []
  const executing: any[] = []

  for (const item of array) {
    const p = Promise.resolve()
      .then(() => iteratorFn(item, array))
      .then(response => {
        if (callback) callback(item, response)
        return { item, response }
      })
    ret.push(p)

    const e: any = p.then(() => executing.splice(executing.indexOf(e), 1))
    executing.push(e)

    if (executing.length >= poolLimit) {
      await Promise.race(executing)
    }
  }

  return Promise.all(ret)
}

export default asyncPool
