import { AxiosResponse } from 'axios'

interface BatchDeleteRes {
  item: AnyObj
  response: AxiosResponse
}
export default function batchDeleteAllSuccess(data: BatchDeleteRes[]) {
  let success = true
  for (const ele of data) {
    if (ele.response.data) {
      success = false
    }
  }
  return success
}
