




























































































































import { Vue, Component, Ref } from 'vue-property-decorator'
import pagination from '@/components/common/pagination/index.vue'
import pageTitle from '@/components/common/pageTitle.vue'
import { areaPath } from '@/utils/getAreaPath'
import SecurityText from '@/components/common/SecurityText/index.vue'
import { tableScrollTop } from '@/utils/dom'
import Modal from '@/components/common/Modal'
import ConfigDialog from './ConfigDialog.vue'
import mitt from '@/utils/mitt'
import asyncPool from '@/utils/async-pool'
import batchDeleteAllSuccess from '@/utils/batchDeleteAllSuccess'

@Component({
  name: 'index',
  components: {
    pagination,
    pageTitle,
    SecurityText
  }
})
export default class InactionTenantPage extends Vue {
  @Ref('paginationNode') readonly paginationNode: pagination

  private query = {
    limit: 20,
    offset: 0
  }
  private personList: any = []
  private loading = false
  private noDataImg = require('@/assets/images/dashboard/noData.png')
  private filterData: AnyObj = {}
  private configDialog = Modal(ConfigDialog)
  selection: AnyObj[] = []

  get rootConfig() {
    return this.$store.state.config?.root
  }

  get root() {
    return this.rootConfig ? this.rootConfig.id : this.$store.state.user.scope
  }

  get areas() {
    return this.$store.state.areas.areas
  }

  get configurable() {
    return this.areas.length > 0 && this.areas[0].level === 1
  }
  batchDelete() {
    if (this.selection.length) {
      this.$confirm('人员一经删除无法恢复，是否确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        cancelButtonClass: 'message-cancel-btn',
        loading: true,
        onConfirm: async () => {
          const res = await asyncPool(this.selection, ({ personId, leaseId }: AnyObj) =>
            this.$api.europa.deletePerson(personId, leaseId)
          )
          if (batchDeleteAllSuccess(res)) {
            this.$message({ message: '删除成功', type: 'success' })
          }
          mitt.emit('residents:change')
        }
      }).catch(() => false)
    }
  }
  selectionChange(selection: AnyObj[]) {
    this.selection = selection
  }
  mounted() {
    if (this.areas.length === 0) {
      this.$store.dispatch('areas/fetchAreas')
    }
    mitt.on(['residents:change'], () => this.getList(this.query.limit, this.query.offset))
    this.getList(this.query.limit, 0)
  }

  private pageChange(data: AnyObj) {
    this.query.limit = data.pageSize
    this.query.offset = (data.pageNo - 1) * data.pageSize
    this.getList(this.query.limit, this.query.offset)
  }
  async getList(pageSize = 20, offset = 0) {
    this.loading = true
    try {
      const resp = await this.getDataList(pageSize, offset)
      if (resp.data && resp.data.data) {
        const { data, count } = resp.data
        this.personList = data.map((item: AnyObj) => {
          Object.assign(item, areaPath(item.areas))
          return item
        })
        this.paginationNode.init({ total: count ? count : 0 })
        tableScrollTop()
      }
    } finally {
      this.loading = false
    }
  }
  private async getDataList(pageSize = 20, offset = 0) {
    const val = this.filterData
    const par: AnyObj = {
      limit: pageSize,
      offset: offset,
      type: 'renter',
      active: false,
      areaId: val.areaId || this.root
    }

    if (val.name && !val.name.match(/^[ ]*$/)) {
      Object.assign(par, { name: val.name, fuzzy: true })
    }
    return this.$api.europa.getPersons(par)
  }

  private handleFilter() {
    this.getList(this.query.limit, 0)
    this.paginationNode.initPage({ current: 1 })
  }

  private residentInfo(obj: AnyObj) {
    this.$router.push({ name: '住户信息查看', params: { id: obj.personId } })
  }

  private deleteInfo(resident: AnyObj) {
    const { personId, leaseId } = resident
    this.$confirm('住户一经删除无法恢复，是否确定删除?', '提示', {
      type: 'warning',
      callback: async action => {
        if (action === 'confirm') {
          const { data } = await this.$api.europa.deletePerson(personId, leaseId)

          if (!data.error) {
            this.$message({ message: '删除成功', type: 'success' })
            mitt.emit('residents:change')
          }
        }
      }
    })
  }
  private showConfigDialog() {
    this.configDialog.open()
  }
}
