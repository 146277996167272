import { Vue } from 'vue-property-decorator'
import { VueConstructor } from 'vue'
import store from '@/store'

type Props = Record<string, any>
type Listeners = Record<string, Function>
type Options = { props?: Props; listeners?: Listeners }

class Modal<T extends VueConstructor> {
  private instance: Vue
  private props: Props = { visible: 'visible' }

  private get propsVisible() {
    return this.props[this.props.visible]
  }

  private get propsVisibleUpdate() {
    return `update:${this.propsVisible}`
  }

  constructor(component: T, options: Options = {}) {
    this.instance = new (Vue.extend(component))({ store })
    this.instance.$on(this.propsVisibleUpdate, (visible: boolean) => {
      this.update({ [this.propsVisible]: visible })
    })

    if (options.props) {
      this.props = options.props
    }
  }

  // TODO: default props?
  open(propsData?: Props) {
    this.update(propsData)
    this.instance.$mount()
    this.update({ [this.propsVisible]: true })

    document.body.appendChild(this.instance.$el)
  }

  close() {
    this.update({ [this.propsVisible]: false })
  }

  update(propsData?: AnyObj) {
    for (const key in propsData) {
      this.instance.$props[key] = propsData[key]
    }
  }

  on(event: string | string[], callback: Function) {
    this.instance.$on(event, callback)
  }

  off(event?: string | string[] | undefined, callback?: Function | undefined) {
    this.instance.$off(event, callback)
  }
}

export default <T extends VueConstructor>(component: T, options?: Options) =>
  new Modal<T>(component, options)
