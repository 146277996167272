import Vue from 'vue'

type Handler<T extends any[]> = (...args: T) => void

/**
 * @key {string} 事件类型 (moduleA/moduleB/...:eventType)
 * @value handler 函数参数
 */
type Events = {
  // TODO: refactor event types
  'houses:update': [string]
  'residents:change': []
  'houses/residents:change': []
  'houses/rooms:change': []
}

const emitter = new Vue()

export const mitt = {
  on: <Key extends keyof Events>(type: Key | Key[], handler: Handler<Events[Key]>) =>
    emitter.$on(type, handler),

  off: <Key extends keyof Events>(type: Key, handler?: Handler<Events[Key]>) =>
    emitter.$off(type, handler),

  emit: <Key extends keyof Events>(type: Key, ...args: Events[Key]) => emitter.$emit(type, ...args)
}

export default mitt
